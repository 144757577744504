<template>
  <div id="pageTable1">
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
       
        <v-flex >
          <v-text-field
            name="siteId"
            id="siteId"
            label="제목"
            type="text"
            required
            v-model="forms.subject"
            ></v-text-field>

          <vue-editor v-model="content" />
        </v-flex>
        
        
        
      </v-layout>
      <v-card-actions class="left-center">
                        <v-btn color="primary"  @click="onSave" >저장</v-btn>
                      
                      </v-card-actions>
    </v-container>
  </div>
</template>

<script>
// const localStore = require('./database/localStore');
// import localStore from '@/database/localStore'
import { VueEditor } from "vue2-editor";

import moment from 'moment-timezone'

export default {
  components: {
    VueEditor 
  },
  data () {
    return {
      content: "",
      forms: {
        content: '',
        subject: '',
        currentTime: 0,
        createdAt: '',
        updatedAt: ''
      },
      table: 'timeline',
      query: {
        firebase: this.$store.getters.firebase,
        ref: this.$store.getters.ref,
        thisRef: '',
        user: this.$store.getters.user,

        queryNext:'',
        refDB:'',

      },

    };
  },
  created () {
    // 브라우저 언어셋
    // var userLang = navigator.language || navigator.userLanguage; 
    // this.$log.warn(userLang)
    //this.startServer() tutorialsRef = firebase.firestore().collection("/tutorials");
    this.query.refDB = this.query.firebase.firestore().collection(this.table)
    this.$log.warn(this.table,this.$store.getters.user.uid)   


    
  },
  mounted () {
   

    //
  },
  methods: {
    
    
    onSave () {
     
      const _this = this
      

      

      const params={
        
        subject: this.forms.subject,
        contents: this.content,
        //percent: this.forms.percent,
        createdAt:this.forms.created = moment().format(),
        updatedAt:this.forms.created = moment().format()
      }
      

      const payload ={
        subject: this.forms.subject,
        contents: this.content,
        //percent: this.forms.percent,
        createdAt:this.forms.created = moment().format(),
        updatedAt:this.forms.created = moment().format()

      }
      this.$log.warn('payload',payload)

      


      _this.$log.warn('params',params)
      //return
      //const refDB = this.query.firebase.firestore().collection(this.forms.siteNameEn).doc(this.$store.getters.user.uid).collection('mysite')
      
      this.query.firebase.firestore().collection(this.table).add(params)
      .then((res) => {
        _this.$log.warn('sasved',res)
              
        //_this.$snotify.success('상담신청이 접수되었습니다')
        _this.$toasted.show('진행사항이 등록되었습니다', {type: 'success'})
          
        _this.isLoading = false
        this.$router.push({ name: 'user.timeline.index'})
      }).catch((e) => {          
        _this.$log.warn('payload',e)
        _this.isLoading = false
      })
     
      


      

    }
   
    

  }
};
</script>
<style >
  

  .v-text-field__prefix {
    width: auto;

  }
</style>